import request from "@/utils/request";

export default {
    commonPost(data) {
        return request.post(data.apiName, data);
    },
    commonGet(params) {
        return request.get(params.apiName, { params });
    },

    commonGetWidthHeader(params) {
        return request({
            url: params.apiName,
            method: 'get',
            params: params,
            raw:true
        });
    }
};