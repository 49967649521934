<template>
  <div class="container">
    <div class="handle-box">
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
      <el-button type="success" icon="el-icon-plus" @click="onAdd">
        添加
      </el-button>
    </div>
    <!--数据表格-->
    <el-table
      :data="list"
      border
      fit
      highlight-current-row
      @row-dblclick="onEdit"
    >
      <el-table-column label="部门名称" prop="departName" />
      <el-table-column label="发票主体" prop="einHead" />
      <el-table-column label="统一代码" prop="ein" />
      <el-table-column label="操作" width="280" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="small"
            @click="onEdit(scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="small"
            @click="onDel(scope.row.id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size.sync="pageInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="400"
    >
    </el-pagination> -->
    <!-- 编辑信息 -->
    <el-dialog
      title="编辑信息"
      width="50%"
      :visible.sync="selectDlgShow"
      :close-on-click-modal="false"
    >
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="ruleForm" :model="form"  label-width="120px">
          <el-form-item label="部门" prop="departId">
            <el-cascader
              :model="form.departId"
              filterable
              :options="departmentTreeList"
              :props="{ value: 'id', label: 'name', checkStrictly: true }"
              clearable
              @change="getDepartId"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="发票主体" required>
            <el-input
              class="input-width"
              v-model="form.einHead"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="统一代码" required>
            <el-input
              class="input-width"
              v-model="form.ein"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="selectDlgShow = false">关 闭</el-button>
        <el-button plain @click="resetForm('ruleForm')">重 置</el-button>

        <el-button type="primary" @click="handleSave(handleType)">
          保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/common";

export default {
  name: "invoiceHeader",
  data() {
    return {
      form: {
        departId: "",
        einHead: "",
        ein: "",
      },
      departmentTreeList: [],
      departmentList: [],
      userList: [],

      mealTimeArr: [
        {
          value: "",
        },
      ],
      handleType: "add",
      pageInfo: {
        currentPage: 1,
        pageSize: 50,
        total: 0,
      },
      list: [],
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: [],
    };
  },
  created() {
    this.getInvoiceList();
    this.getTreeList();
  },
  methods: {
    getDepartId(e) {
      this.form.departId = e[e.length - 1];

    },
    async getTreeList() {
      let res = await api.commonPost({
        apiName: "/department/getTreeList",
      });
      this.departmentTreeList = res.data;
      console.log("res", res);
    },
    onRefresh() {
      this.fetchData();
    },
    onDel(id) {
      console.log("id", id);

      this.$confirm("您确定要删除此条记录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = [];
        ids.push(id);
        api
          .commonPost({
            apiName: "/meituanInvoice/remove",
            ids: ids,
          })
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getInvoiceList();
          });
      });
    },
    //
    handleSizeChange(e) {
      this.pageInfo.pageSize = e;
      this.getInvoiceList();
    },
    handleCurrentChange(e) {
      this.pageInfo.currentPage = e;
      this.getInvoiceList();
    },

    async handleSave(type) {
      console.log("type", type);

      this.dlgLoading = false;

      // var obj = {};
      let apiName = "";
      if (type == "add") {
        apiName = "/meituanInvoice/create";
      } else {
        apiName = "/meituanInvoice/update";
      }
      this.form.apiName = apiName;

      let res = await api.commonPost(this.form);

      if (res.code == 200) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.selectDlgShow = false;
        this.dlgLoading = false;
        this.getInvoiceList();
      }
    },

    //添加
    onAdd() {
      this.handleType = "add";
      this.form = {
        approverId: "",
        departmentId: "",
        mealTime: "",
      };
      this.selectDlgShow = true;
    },
    //编辑
    onEdit(row) {
      this.handleType = "edit";
      this.form = row;
      this.selectDlgShow = true;
    },
    //查询列表
    async getInvoiceList() {
      try {
        let res = await api.commonGet({
          apiName: "/meituanInvoice",
          ...this.pageInfo,
        });
        if (res.code == 200) {
          this.list = res.data.data;
        }
      } catch (error) {
        console.log("error", error);
      }
    },

    //获取分局部门列表，用于添加

    async getDepartList() {
      let res = await api.commonPost({ apiName: "/department/getBranchList" });
      this.departmentList = res.data;
    },

    getRangTimeStr(arr) {
      let strArr = [];
      arr.forEach((element) => {
        strArr.push(element.value[0] + "~" + element.value[1]);
      });
      let permission = strArr.join(",");
      return permission;
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.input-width {
  width: 250px;
}
</style>
